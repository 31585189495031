
import { Options, mixins } from "vue-class-component"

import ContactInfo from "@/mixins/ContactInfo"
import CommonPage from "@/mixins/CommonPage"
import { openChat } from "@/utils"

@Options({
  components: {}
})
export default class ConfirmName extends mixins(CommonPage, ContactInfo) {
  show = false

  onNext() {
    if (!this.client?.hasEmail) {
      this.$router.push({
        name: "ConfirmEmail",
        query: {
          id: this.id,
          status: this.status
        }
      })
    } else if (
      !this.client?.hasFirstName ||
      !this.client?.hasLastName ||
      !this.client?.hasPhoneNumber
    ) {
      this.$router.push({
        name: "ContactInformation",
        query: {
          id: this.id,
          status: this.status
        }
      })
    } else {
      this.onAcceptInvite()
    }
  }

  onToggleView() {
    this.show = true
  }

  onOpenChat() {
    openChat()
  }
}
